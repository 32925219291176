import React, { useRef } from "react";
import "./ContactUs.css";
import NavbarOther from "../components/NavbarOther/NavbarOther";
import contact from "../assets/company/contactus.jpg";
import Footer from "../components/Footer/Footer";
import Form from "../components/Form/EmailForm";
import { Helmet } from "react-helmet";

function ContactUs() {
  const form = useRef();
  return (
    <>
      <Helmet>
        <title>Contact Vkay Stone Veneer | Get in Touch with Us</title>
        <meta
          name="description"
          content="Have questions about our stone veneer products? Contact Vkay Stone Veneer today. Our team is here to assist you with product inquiries, orders, and more."
        />
        <meta
          name="keywords"
          content="Contact Vkay Stone Veneer, Get in Touch, Contact Us, Customer Service, Customer Support, Request a Quote, Product Inquiries, Sales Inquiries, General Inquiries, Find Us, Our Location, Office Location, Contact Information, Phone Number, Email Address, Business Hours, Visit Us, Schedule a Visit, Contact Form, Reach Out to Us"
        />
        <meta property="og:title" content="Contact Us | Vkay Stone Veneer" />
        <meta
          property="og:description"
          content="Reach out to Vkay Stone Veneer for all your natural stone veneer needs. Contact us via phone, email, or visit our office. We're here to help!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.vkaystoneveneer.com/contact"
        />
      </Helmet>

      <NavbarOther></NavbarOther>
      <div className="contact-us-container">
        <div className="contact-form">
          <h1 className="contact-heading">GET IN TOUCH</h1>
          <Form className="cf" ref={form}></Form>
        </div>
        <div className="contact-map">
          <img
            className="contact-address"
            src={contact}
            alt="contact-information"
          />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default ContactUs;
