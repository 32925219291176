import React, { useState } from "react";

function EmailForm() {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const mailtoLink = `mailto:vkaystoneveneer@gmail.com?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;

    window.location.href = mailtoLink;
  };

  return (
    
      <form onSubmit={handleSubmit}>
        <div className="half left cf">
          <input
            className="input-box-shadow"
            type="text"
            id="subject"
            placeholder="Subject:"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="half left cf">
          <textarea
            className="input-box-shadow"
            id="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button id="input-submit" type="submit">
          Send Email
        </button>
      </form>
  );
}

export default EmailForm;
